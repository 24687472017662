body {
  background-image: url("./evweev.jpg"); /* 배경 이미지 경로를 public 폴더로 수정 */
  background-size: cover;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Arial", sans-serif;
  flex-direction: column; /* 세로 방향으로 배치 */
}

.playground-container {
  text-align: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  width: 340px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem; /* 아래에 간격 추가 */
}

.game-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.instructions {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.refresh-button {
  display: inline-block;
  background: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: background 0.3s;
}

.refresh-button:hover {
  background: #45a049;
}

.game-info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.score,
.best-score {
  font-size: 1.25rem;
  margin: 0;
}

.canvas-container {
  display: inline-block;
  border: 2px solid #000;
  background: rgba(255, 255, 255, 0.7); /* 약간 투명하게 설정 */
  border-radius: 10px;
  padding: 0.5rem;
  margin-top: 1rem;
}
